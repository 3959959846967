
import Vue from 'vue'

export default Vue.extend<Data, Computed, Methods>({
  name: 'LoginLandingPage',
  mounted() {
    this.$naver.signIn()
  },
  methods: {},
})

interface Data {}
interface Computed {}
interface Methods {}
